<template>
  <div class="position-absolute container-data">
    <slot>
    </slot>
  </div>
</template>

<script>
export default {

}
</script>
