class ConvertTime {
  getNameDay (index) {
    const day = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu']
    return day[index]
  }

  getNameMonth (index) {
    const month = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
    return month[index]
  }

  timeToNumber (time) {
    if (!time) return 0
    const hour = time.split(':')[0]
    const minute = time.split(':')[1]
    return parseInt(hour) * 60 + parseInt(minute)
  }
}

export default ConvertTime
