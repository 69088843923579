<template>
  <div>
    <p class="fs-62 font-weight-bold text-white position-head-overlay w-100">{{ headerText }}</p>
    <img :src="icon" class="img-resize-40 position-center-overlay" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: ''
    },
    headerText: {
      type: String,
      default: ''
    }
  }
}
</script>
<style></style>
