<template>
    <template v-if="qrData.deviceId">
        <overlay-info :device-id="qrData.deviceId" :qr-data="qrData"/>
    </template>
</template>

<script>
import OverlayInfo from './OverlayInfo.vue'
import { useSelector } from '@xstate/vue'
export default {
  components: {
    OverlayInfo
  },
  data () {
    const selectCount = (state) => {
      return state.context.deviceId && state.context.socketId ? {
        deviceId: state.context.deviceId,
        socketId: state.context.socketId
      } : {}
    }
    const count = useSelector(window.fetchMachine, selectCount)
    return {
      qrData: count
    }
  },
  mounted () {
    // this.interpret = window.fetchMachine
    // this.interpret = useInterpret(window.dataMachine, '', (evt) => {
    //   console.log('check', evt)
    // })
  },
  methods: {
    sendReject () {
      window.fetchMachine.send({
        type: 'on:admin:reject'
      })
    }
  }
}
</script>

<style>

</style>
