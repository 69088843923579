<template>
    <div class="position-bottom-left-overlay">
          <qrcode-vue :value="JSON.stringify(qrData)" :margin="4" @click="clickButton" style="border-radius: 20px;" :size="200"/>
        <div class="mt-2">
            <label class=" c-pink-dokter fw-700 fs-22 text-center input-text-qr">
               {{deviceId}}
            </label>
        </div>
        <p class="fs-28 fw-600 text-white mt-4" style="width: 425px">{{$l.detectLanguage('call Admin')}}</p>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import { mapState } from 'vuex'

export default {
  components: {
    QrcodeVue
  },
  data () {
    // const interpret = useInterpret(window.dataMachine)
    return {
    }
  },
  methods: {
    clickButton () {
      console.log(this.qrData)
    }
  },
  props: {
    deviceId: {
      type: String,
      default: '1111'
    },
    qrData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('DocumentHandler', {
      uniqueId: state => state.uniqueUUID
    })
  }
}
</script>

<style>

</style>
