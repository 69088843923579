<template>
  <div class="position-bottom-overlay">
    <label class=" fs-38 fw-700 input-text-bottom-overlay" v-show="isOfflineMachine">
      <img class="mt-1x" src="@/assets/icon/offline.svg" /> Offline
    </label>
    <label v-if="!isOfflineMachine" class="fs-38 fw-600 c-leisure-blue overlay-countdown">
      Response Time: <span :style="{color: styleColor}">{{ pingTime }} ms</span>
    </label>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  beforeMount () {
    clearInterval(this.intervalSet)
  },
  props: {
    countDown: {
      type: Boolean,
      default: false
    },
    isOfflineMachine: { type: Boolean },
    colorLatency: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('DocumentHandler', {
      pingTime: state => state.pingTime,
      isOffline: state => state.isOffline
    }),
    styleColor () {
      const objcolorLatency = this.colorLatency
      try {
        const propertyNames = Object.keys(objcolorLatency)
        const key = propertyNames.sort((a, b) => parseInt(a) - parseInt(b)).findIndex(item => parseInt(item) > this.pingTime)
        if (key >= 0) {
          const indexKey = key > 0 ? key - 1 : 0
          const valKey = propertyNames[indexKey]
          return objcolorLatency[valKey.toString()]
        } else {
          return objcolorLatency[propertyNames[objcolorLatency.length - 1]]
        }
      } catch {
        //
      }
      return '#000'
    }
  },
  data () {
    return {
      isInterval: '',
      intervalSet: null
    }
  },
  mounted () {
    this.intervalSet = setInterval(() => {
      const start = Date.now()
      window.socket.emit('ping', () => {
        const duration = Date.now() - start
        this.setPingTime(duration)
      })
    }, 5000)
  },
  methods: {
    ...mapActions({
      getPingTime: 'DocumentHandler/GET_PING_INTERVAL_SERVER'
    }),
    ...mapMutations({
      setPingTime: 'DocumentHandler/SET_PING_TIME'
    })
  }
}
</script>

<style>
.overlay-countdown{
    background: white;
    border: 2px solid #3F68A6 ;
    padding: 12px 20px;
    border-radius: 20px;
}
</style>
