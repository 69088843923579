<template>
  <OverlayFooter :count-down="true" :color-latency="deviceConfig.objectColor" :is-offline-machine="!deviceConfig.isConnect"/>
</template>

<script>
import OverlayFooter from './OverlayFooter.vue'
import { useSelector } from '@xstate/vue'
export default {
  components: {
    OverlayFooter
  },
  data () {
    const connected = (state) => {
      return {
        isConnect: state.context.connected,
        objectColor: state.context.config && state.context.config.serverConfig && state.context.config.serverConfig.latencyIndicator ? state.context.config.serverConfig.latencyIndicator : {}
      }
      // return state.context.connected
    }
    const deviceConfig = useSelector(window.fetchMachine, connected)
    return {
      deviceConfig
    }
  },
  methods: {
    sendReject () {
      window.fetchMachine.send({
        type: 'on:admin:reject'
      })
    }
  }
}
</script>

<style>

</style>
